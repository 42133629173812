/**
 * Created by rana on 7/4/15.
 */

function getFoodSearchUrl() {
    return '/food-nutrition/food/search';
}

var translate = {

    data: {},

    get: function (key) {

        return this.data[key];

    },

    put: function (value, key) {

        if (arguments.length == 1)
            this.data = jQuery.extend({}, this.data, value);

        else
            this.data[key] = value;

        return this;

    },

    number: function (number) {
        number += '';
        for (var i = 0; i < 10; i++) {
            number = number.replace((new RegExp((i + ''), 'g')), this.get(i));
        }

        return number;
    }

};

/*
 Source http://www.sitepoint.com/javascript-generate-lighter-darker-color/
 */
function ColorLuminance(hex, lum) {

    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
    }

    return rgb;
}
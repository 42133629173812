/**
 * Created by rana on 9/1/15.
 */
(function ($) {
    "use strict";

    $.fn.animateCss = function (animation) {
        animation = animation || 'bounceIn';
        $(this).addClass(animation + ' animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
            $(this).removeClass(animation + ' animated');
        });
        return this;
    }
}(jQuery));
/**
 * Created by rana on 8/29/15.
 */
(function ($) {
    var _registerEvent = function () {
        $(document).on('laravel.form-errors', function (e, data) {
            var options = data.options || {};
            $.formErrors(e, data.jqXHR, data.textStatus, data.errorThrown, options);
        });
    };

    $.formErrors = function (e, jqXHR, textStatus, errorThrown, options) {
        var _target = e.target;
        var _options = {
            class: 'has-error',
            animateCssEffect: 'bounceIn',
            parent: '.form-group',
            child: '.help-block.text-danger'
        };

        $.extend(_options, options);

        var _hasAnimateCss = !!$.fn.animateCss;

        if (jqXHR.status == 422) {
            $.each(jqXHR.responseJSON, function (i, v) {
                _hasAnimateCss ?
                    $(_target)
                        .find('[name="' + i + '"]')
                        .closest(_options.parent)
                        .addClass(_options.class)
                        .find(_options.child)
                        .html(v.shift())
                        .animateCss(_options.animateCssEffect)
                    :
                    $(_target)
                        .find('[name="' + i + '"]')
                        .closest(_options.parent)
                        .addClass(_options.class)
                        .find(_options.child)
                        .hide()
                        .html(v.shift())
                        .slideDown(500);
            });
        }
    };

    _registerEvent();
}(jQuery));
/**
 * Created by rana on 8/23/15.
 */
jQuery(function () {
    jQuery.fn.ajaxForm && jQuery(document).find('#diet-plan').ajaxForm({
        beforeSubmit: function (formData, jqForm, options) {
            jQuery(jqForm[0]).find('button[type="submit"]').prop('disabled', '').find('i').removeClass('hidden');
            jQuery(jqForm[0]).find('.form-row').removeClass('has-error').find('.form-submit-text').html('');

            try {
                if (!jQuery('input[name="sex"]').is(':checked')) {
                    throw {
                        'sex': [
                            translate.get('please_select_sex')
                        ]
                    };
                }

                var elemMessage = {
                    age: translate.get('please_select_age'),
                    weight: translate.get('please_select_weight')
                };

                for (var i in elemMessage) {
                    if (!jQuery('#' + i).val().length) {
                        var _t = {};
                        _t[i] = [elemMessage[i]];
                        throw _t;
                    }
                }
                ;

                if (jQuery('#height_ft').val() == 0 && jQuery('#height_inch').val() == 0) {
                    throw {
                        'height_inch': [
                            translate.get('please_select_valid_height')
                        ]
                    };
                }

                if (!jQuery('#lifestyle').val().length) {
                    throw {
                        'lifestyle': [
                            translate.get('please_select_lifestyle')
                        ]
                    };
                }
            } catch (e) {
                jQuery(jqForm[0]).trigger('laravel.form-errors', {
                    jqXHR: {
                        status: 422,
                        responseJSON: e
                    },
                    textStatus: null,
                    errorThrown: null,
                    options: {
                        parent: '.form-row',
                        child: '.form-submit-text'
                    }
                });
                jQuery(jqForm[0]).find('button[type="submit"]').removeProp('disabled').find('i').addClass('hidden');
                return false;
            }
        },
        success: function (responseText, statusText, xhr, $form) {
            window.location.href = '/food-nutrition/diet-plan/create';
        },
        error: function (jqXHR, textStatus, errorThrown) {
            jQuery('#diet-plan').find('.form-row').removeClass('has-error').find('.form-submit-text').html('');
            jQuery('#diet-plan').find('button[type="submit"]').removeProp('disabled').find('i').addClass('hidden');
            if (jqXHR.status == 403) {
                window.location.href = '/login';
            }

            jQuery('#diet-plan').trigger('laravel.form-errors', {
                jqXHR: jqXHR,
                textStatus: textStatus,
                errorThrown: errorThrown,
                options: {
                    parent: '.form-row',
                    child: '.form-submit-text'
                }
            });
        }
    });
});